import { SVGProps } from 'react'

const PluginsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47935 2V2.69444V7.09363L14.5202 7.09363V2.69444V2H15.9091V2.69444V7.09363H17.039H18.4279V8.48251L18.4279 12.6525C18.4279 14.8151 16.6747 16.5683 14.512 16.5683H12.863V21.3057V22.0001H11.4741V21.3057V16.5683L9.48523 16.5683C7.32254 16.5683 5.56934 14.8151 5.56934 12.6524L5.56934 8.48251L5.56934 7.09363H6.95822H8.09047V2.69444V2H9.47935ZM14.512 15.1795L9.48523 15.1795C8.0896 15.1795 6.95822 14.0481 6.95822 12.6524L6.95822 8.48251L17.039 8.48251L17.039 12.6525C17.039 14.0481 15.9076 15.1795 14.512 15.1795Z"
      fill="currentColor"
    />
  </svg>
)

export default PluginsIcon
