import { SVGProps } from 'react'

const AuctionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3155 6.17822L14.1582 2.33552C14.1679 2.32591 14.1679 2.31032 14.1582 2.30071L12.9355 1.07801C12.9259 1.0684 12.9103 1.0684 12.9007 1.07801L9.05804 4.92071C9.04842 4.93032 9.04842 4.94591 9.05804 4.95552L10.2807 6.17822C10.2903 6.18783 10.3059 6.18783 10.3155 6.17822ZM14.6886 2.86585C14.9911 2.56334 14.9911 2.07288 14.6886 1.77038L13.4659 0.54768C13.1634 0.245174 12.6729 0.245174 12.3704 0.54768L8.52771 4.39038C8.2252 4.69288 8.2252 5.18334 8.52771 5.48585L9.7504 6.70855C10.0529 7.01105 10.5434 7.01105 10.8459 6.70855L11.0175 6.53696L11.0456 6.56513L12.4465 7.96601L12.2235 8.18312L0.588794 19.5088C0.282244 19.8072 0.275642 20.2976 0.574049 20.6041L1.39251 21.4449C1.69092 21.7515 2.18133 21.7581 2.48788 21.4597L14.1226 10.134L14.3719 9.89136L15.2883 10.8078L15.4289 10.9484L15.2577 11.1196C14.9552 11.4221 14.9552 11.9126 15.2577 12.2151L16.4804 13.4378C16.7829 13.7403 17.2733 13.7403 17.5759 13.4378L21.4186 9.5951C21.7211 9.29259 21.7211 8.80213 21.4186 8.49963L20.1959 7.27693C19.8933 6.97442 19.4029 6.97442 19.1004 7.27693L18.7638 7.61356L18.6231 7.47292L14.3805 3.23028L14.3523 3.20211L14.6886 2.86585ZM17.0455 12.9075L20.8882 9.06477C20.8978 9.05515 20.8978 9.03957 20.8882 9.02995L19.6655 7.80726C19.6559 7.79765 19.6403 7.79765 19.6307 7.80726L15.788 11.65C15.7784 11.6596 15.7784 11.6752 15.788 11.6848L17.0107 12.9075C17.0203 12.9171 17.0359 12.9171 17.0455 12.9075ZM18.0928 8.00325L13.8501 3.76061L11.576 6.0348L15.8186 10.2774L18.0928 8.00325ZM12.7466 8.72054L1.11194 20.0462C1.1022 20.0557 1.10199 20.0712 1.11147 20.081L1.92993 20.9218C1.93941 20.9315 1.955 20.9317 1.96474 20.9222L13.5994 9.59661L12.7466 8.72054Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
)

export default AuctionsIcon
