import { SVGProps } from 'react'

const OverviewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5855 0.760986H12.4138L17.196 3.52199L21.4706 5.98991L21.5299 5.95567L21.944 6.19478V6.26327L21.9782 6.283V6.76123L21.944 6.78096V11.7168V17.2388L21.5299 17.9562L12.4138 23.2193V23.2396L11.9996 23.4787L11.9821 23.4686L11.9655 23.4782L11.5513 23.2391V23.2199L2.43518 17.9567L2.02103 17.2393L2.02103 6.76126L2.021 6.76124V6.28301L2.02103 6.28299L2.02103 6.19531L2.43518 5.9562L2.51112 6.00004L8.74172 2.4028L8.6973 2.37715L9.52561 1.89893L9.57006 1.92459L11.5855 0.760986ZM7.46579 8.86062L3.33946 6.47828L9.54996 2.89265L13.6763 5.27499L7.46579 8.86062ZM2.84934 7.23948L6.97567 9.62182H6.97576L7.11663 9.70315L7.11663 14.845V19.7031L2.84934 17.2393L2.84934 7.23948ZM8.29411 9.33885L9.92807 8.39548L13.616 10.5247L11.9821 11.4681L8.29411 9.33885ZM10.7564 7.91725L14.5247 5.74161L18.2127 7.87085L14.4443 10.0465L10.7564 7.91725ZM19.041 7.39263L14.3078 4.65993L10.3984 2.40282L11.9996 1.47833L16.3677 4.00022L20.6422 6.46814L19.041 7.39263ZM21.1157 7.25919L19.3869 8.2573V13.49V18.2369L21.1157 17.2388V12.195V7.25919ZM18.5586 8.73552L14.7966 10.9075V15.9478L14.7966 20.8871L18.5586 18.7151V13.9683V8.73552ZM12.4138 12.2832H12.4138V22.2629L13.9683 21.3654V16.426V11.3858L12.4138 12.2832ZM11.5513 12.2635L8.07308 10.2554V14.845L8.07308 20.2553L11.5513 22.2634V12.2635Z"
      fill="currentColor"
    />
  </svg>
)

export default OverviewIcon
