import { SVGProps } from 'react'

const TransactionSignedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="86"
    height="87"
    viewBox="0 0 86 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.6984 41.9699C64.8545 42.8137 64.8545 44.1854 65.6984 45.0293C66.1063 45.4386 66.6502 45.6639 67.2281 45.6639C67.8059 45.6639 68.3492 45.4386 68.7578 45.0293C69.1663 44.6208 69.3916 44.0775 69.3916 43.4997C69.3916 42.9218 69.1663 42.3785 68.7571 41.97C67.9406 41.1528 66.5142 41.1527 65.6984 41.9699Z"
      fill="black"
    />
    <path
      d="M55.2123 66.6769C55.7902 66.6769 56.3335 66.4516 56.742 66.0423C57.1513 65.6344 57.3766 65.0905 57.3766 64.5126C57.3766 63.9347 57.1513 63.3915 56.742 62.9829C55.9255 62.1644 54.4991 62.1644 53.6826 62.9829C53.2733 63.3908 53.048 63.9347 53.048 64.5126C53.048 65.0905 53.2733 65.6337 53.6826 66.0423C54.0905 66.4516 54.6338 66.6769 55.2123 66.6769Z"
      fill="black"
    />
    <path
      d="M38.6855 69.5274C39.2393 69.5274 39.7932 69.3161 40.2152 68.8949C40.6237 68.487 40.849 67.9431 40.849 67.3652C40.849 66.7873 40.6237 66.2441 40.2152 65.8355C39.3986 65.017 37.9729 65.017 37.1557 65.8355C36.3119 66.6793 36.3119 68.051 37.1557 68.8949C37.5776 69.3169 38.1316 69.5274 38.6855 69.5274Z"
      fill="black"
    />
    <path
      d="M18.7761 50.1321C17.9336 50.976 17.9336 52.3483 18.7761 53.1923C19.198 53.6135 19.7519 53.8248 20.3064 53.8248C20.8603 53.8248 21.4142 53.6135 21.8361 53.1923C22.6793 52.3484 22.6793 50.9761 21.8361 50.1321C20.9923 49.2889 19.62 49.2889 18.7761 50.1321Z"
      fill="black"
    />
    <path
      d="M37.1552 18.1069C36.312 18.9508 36.312 20.3231 37.1552 21.1664C37.5638 21.575 38.107 21.8002 38.6856 21.8002C39.2634 21.8002 39.8067 21.575 40.2153 21.1664C41.0591 20.3225 41.0591 18.9502 40.2153 18.1069C39.3981 17.2891 37.9724 17.2891 37.1552 18.1069Z"
      fill="black"
    />
    <path
      d="M55.2123 24.6514C55.7902 24.6514 56.3335 24.4261 56.742 24.0176C57.1513 23.609 57.3766 23.0657 57.3766 22.4879C57.3766 21.91 57.1513 21.3667 56.742 20.9575C55.8995 20.1136 54.5259 20.1136 53.6826 20.9575C53.2733 21.3661 53.048 21.9093 53.048 22.4879C53.048 23.0657 53.2733 23.609 53.6826 24.0176C54.0905 24.4261 54.6338 24.6514 55.2123 24.6514Z"
      fill="black"
    />
    <path
      d="M61.4698 29.8695C62.0244 29.8695 62.5783 29.6589 63.0002 29.237C63.844 28.3938 63.844 27.0208 63.0002 26.1775C62.155 25.3337 60.784 25.3357 59.9401 26.1775C59.0962 27.0213 59.0962 28.3937 59.9401 29.237C60.3627 29.6589 60.916 29.8695 61.4698 29.8695Z"
      fill="black"
    />
    <path
      d="M64.3188 33.8097C63.4749 34.6529 63.4749 36.0259 64.3188 36.8692C64.74 37.2911 65.2946 37.5017 65.8485 37.5017C66.403 37.5017 66.9569 37.2911 67.3789 36.8692C68.2227 36.0253 68.2227 34.653 67.3789 33.8097C66.561 32.9925 65.1354 32.9925 64.3188 33.8097Z"
      fill="black"
    />
    <path
      d="M64.3208 53.192C64.7421 53.6133 65.2967 53.8246 65.8505 53.8246C66.4051 53.8246 66.959 53.6133 67.3809 53.192C68.2234 52.3482 68.2234 50.9758 67.3809 50.1319C66.5371 49.29 65.1647 49.288 64.3208 50.1319C63.4783 50.975 63.4783 52.3481 64.3208 53.192Z"
      fill="black"
    />
    <path
      d="M61.4688 61.101C62.0234 61.101 62.578 60.8897 62.9992 60.4685C63.8417 59.6246 63.8417 58.2523 62.9992 57.4083C62.154 56.5645 60.781 56.5665 59.9391 57.4083C59.0966 58.2521 59.0966 59.6245 59.9391 60.4685C60.3617 60.8897 60.915 61.101 61.4688 61.101Z"
      fill="black"
    />
    <path
      d="M47.1167 69.5275C47.6706 69.5275 48.2252 69.3162 48.6464 68.8949C49.4902 68.0511 49.4902 66.6794 48.6464 65.8355C47.8299 65.017 46.4048 65.017 45.5863 65.8355C44.7424 66.6793 44.7424 68.051 45.5863 68.8949C46.0082 69.3169 46.5622 69.5275 47.1167 69.5275Z"
      fill="black"
    />
    <path
      d="M24.5376 61.1007C25.0915 61.1007 25.6454 60.8895 26.0673 60.4682C26.9105 59.6244 26.9105 58.252 26.0673 57.4081C25.2235 56.5656 23.8511 56.5656 23.0079 57.4081C22.1654 58.2519 22.1654 59.6243 23.0079 60.4682C23.4291 60.8895 23.9831 61.1007 24.5376 61.1007Z"
      fill="black"
    />
    <path
      d="M18.7706 45.6429C19.3245 45.6429 19.8791 45.4316 20.301 45.0103C21.1448 44.1665 21.1448 42.7942 20.301 41.9509C19.4565 41.107 18.0841 41.107 17.2415 41.9509C16.3977 42.7947 16.3977 44.1671 17.2415 45.0103C17.6628 45.4316 18.2167 45.6429 18.7706 45.6429Z"
      fill="black"
    />
    <path
      d="M18.7765 36.567C19.1985 36.989 19.7524 37.1996 20.3069 37.1996C20.8608 37.1996 21.4147 36.989 21.8366 36.567C22.2452 36.1585 22.4705 35.6152 22.4705 35.0367C22.4705 34.4588 22.2452 33.9156 21.8366 33.507C21.0194 32.6898 19.5943 32.6898 18.7771 33.507C17.9333 34.3508 17.9334 35.7238 18.7765 36.567Z"
      fill="black"
    />
    <path
      d="M23.0053 29.2383C23.4139 29.6469 23.9572 29.8722 24.5357 29.8722C25.1136 29.8722 25.6568 29.6469 26.0654 29.2383C26.474 28.8304 26.6993 28.2865 26.6993 27.7086C26.6993 27.1307 26.474 26.5875 26.0654 26.1782C25.2216 25.3344 23.8492 25.3344 23.006 26.1782C22.1621 27.0221 22.1622 28.395 23.0053 29.2383Z"
      fill="black"
    />
    <path
      d="M29.418 20.9567C28.5742 21.8006 28.5742 23.1729 29.418 24.0162C29.8399 24.4381 30.3938 24.6487 30.9484 24.6487C31.5023 24.6487 32.0562 24.4381 32.4781 24.0162C33.3219 23.1724 33.3219 21.8 32.4781 20.9567C31.6329 20.1136 30.2606 20.1142 29.418 20.9567Z"
      fill="black"
    />
    <path
      d="M30.7888 66.6769C31.3666 66.6769 31.9099 66.4516 32.3185 66.0423C32.727 65.6344 32.9523 65.0905 32.9523 64.5126C32.9523 63.9347 32.727 63.3915 32.3185 62.9829C31.5019 62.1644 30.0762 62.1644 29.259 62.9829C28.4152 63.8267 28.4152 65.1984 29.259 66.0424C29.6676 66.4516 30.2109 66.6769 30.7888 66.6769Z"
      fill="black"
    />
    <path
      d="M47.1157 21.7997C47.6936 21.7997 48.2368 21.5745 48.6454 21.1659C49.4879 20.322 49.4879 18.9497 48.6454 18.1064C47.8289 17.2892 46.4038 17.2892 45.5853 18.1064C44.7428 18.9503 44.7428 20.3226 45.5853 21.1659C45.9945 21.5745 46.5378 21.7997 47.1157 21.7997Z"
      fill="black"
    />
    <path
      d="M28.3923 11.9662C27.5485 12.81 27.5485 14.1824 28.3923 15.0256C28.8142 15.4476 29.3681 15.6582 29.922 15.6582C30.4759 15.6582 31.0304 15.4475 31.4524 15.0256C32.2962 14.1818 32.2962 12.8094 31.4524 11.9662C30.6085 11.123 29.2362 11.1217 28.3923 11.9662Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M20.6965 16.4658C19.8527 17.3096 19.8527 18.682 20.6965 19.5252C21.1044 19.9338 21.6483 20.1591 22.2262 20.1591C22.8041 20.1591 23.3473 19.9338 23.7566 19.5252C24.5998 18.6814 24.5998 17.309 23.7566 16.4658C22.9387 15.6486 21.5132 15.6486 20.6965 16.4658Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M18.3986 24.4093C18.3986 23.8314 18.1733 23.2882 17.7647 22.8789C16.9209 22.0351 15.5485 22.0364 14.7053 22.8789C14.2967 23.2875 14.0714 23.8307 14.0714 24.4093C14.0714 24.9872 14.2967 25.5304 14.7053 25.939C15.1272 26.3609 15.6811 26.5715 16.2356 26.5715C16.7895 26.5715 17.3434 26.3609 17.7653 25.939C18.1732 25.531 18.3986 24.9878 18.3986 24.4093Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M12.3855 34.5068C12.9394 34.5068 13.4939 34.2961 13.9158 33.8742C14.7597 33.0304 14.7597 31.658 13.9158 30.8148C13.0727 29.9716 11.7003 29.9709 10.8564 30.8148C10.0125 31.6586 10.0125 33.031 10.8564 33.8742C11.2776 34.2955 11.8316 34.5068 12.3855 34.5068Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M8.93194 39.57C8.52336 39.9779 8.29807 40.5218 8.29807 41.0997C8.29807 41.6776 8.52336 42.2208 8.93194 42.6301C9.35386 43.0513 9.90776 43.2626 10.4616 43.2626C11.0155 43.2626 11.5701 43.0513 11.992 42.6301C12.4006 42.2215 12.6259 41.6783 12.6259 41.0997C12.6259 40.5218 12.4006 39.9786 11.992 39.57C11.1482 38.7268 9.77589 38.7255 8.93194 39.57Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M12.6138 48.2063C11.7693 47.3625 10.3969 47.3638 9.55429 48.2063C8.71112 49.0502 8.71112 50.4225 9.55429 51.2665C9.97622 51.6877 10.5301 51.899 11.084 51.899C11.6379 51.899 12.1924 51.6877 12.6144 51.2665C13.4569 50.4233 13.4569 49.0503 12.6138 48.2063Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M12.3 56.6342C11.8914 57.0421 11.6661 57.586 11.6661 58.1639C11.6661 58.7418 11.8914 59.285 12.3 59.6936C12.7085 60.1028 13.2518 60.3282 13.8297 60.3282C14.4075 60.3282 14.9508 60.1029 15.36 59.6936C15.7686 59.2857 15.9939 58.7418 15.9939 58.1639C15.9939 57.586 15.7686 57.0428 15.36 56.6342C14.5422 55.8157 13.1171 55.8157 12.3 56.6342Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M20.7638 67.2303C21.6077 66.3865 21.6077 65.0148 20.7638 64.1708C19.92 63.3283 18.5476 63.3283 17.7044 64.1708C17.2958 64.5788 17.0705 65.1227 17.0705 65.7005C17.0705 66.2784 17.2958 66.8217 17.7044 67.2302C18.1123 67.6395 18.6562 67.8648 19.2341 67.8648C19.8113 67.8648 20.3553 67.6396 20.7638 67.2303Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M27.6079 69.6803C26.7907 68.8617 25.3649 68.8617 24.5484 69.6803C24.1398 70.0882 23.9145 70.6321 23.9145 71.2099C23.9145 71.7878 24.1398 72.3311 24.5484 72.7396C24.9563 73.1489 25.5002 73.3742 26.0781 73.3742C26.656 73.3742 27.1992 73.1489 27.6085 72.7396C28.4516 71.8958 28.4517 70.5235 27.6079 69.6803Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M32.6235 73.1778C31.7804 74.0216 31.7804 75.394 32.6235 76.238C33.0455 76.6592 33.5993 76.8705 34.1532 76.8705C34.7071 76.8705 35.2617 76.6592 35.6836 76.238C36.5268 75.3941 36.5268 74.0218 35.6836 73.1778C34.8384 72.334 33.4661 72.334 32.6235 73.1778Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M41.4679 74.4307C40.6248 75.2746 40.6248 76.6469 41.4679 77.4909C41.8898 77.9121 42.4431 78.1234 42.9976 78.1234C43.5515 78.1234 44.1061 77.9121 44.5273 77.4909C45.3711 76.647 45.3711 75.2747 44.5273 74.4307C43.6848 73.5882 42.3119 73.5882 41.4679 74.4307Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M50.3189 73.1768C49.475 74.0206 49.475 75.393 50.3189 76.237C50.7401 76.6582 51.2947 76.8695 51.8486 76.8695C52.4024 76.8695 52.957 76.6582 53.3783 76.237C54.2208 75.3931 54.2208 74.0208 53.3783 73.1768C52.5358 72.3343 51.1621 72.3343 50.3189 73.1768Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M58.3355 69.6803C57.4917 70.5241 57.4917 71.8958 58.3355 72.7397C58.7447 73.149 59.288 73.3743 59.8659 73.3743C60.4438 73.3743 60.987 73.149 61.3956 72.7397C62.2394 71.8959 62.2394 70.5242 61.3956 69.6803C60.5791 68.8617 59.154 68.8617 58.3355 69.6803Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M65.1722 63.9106C64.763 64.3185 64.5377 64.8624 64.5377 65.4403C64.5377 66.0182 64.7629 66.5614 65.1722 66.9707C65.5935 67.3919 66.148 67.6032 66.7019 67.6032C67.2558 67.6032 67.8104 67.3919 68.2316 66.9707C68.6409 66.5614 68.8662 66.0182 68.8662 65.4403C68.8662 64.8624 68.6409 64.3192 68.2316 63.9106C67.3864 63.0674 66.0148 63.0674 65.1722 63.9106Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M71.8468 60.6623C72.4007 60.6623 72.9552 60.451 73.3765 60.0297C74.2203 59.1859 74.2203 57.8135 73.3765 56.9696C72.534 56.1271 71.1603 56.1271 70.317 56.9696C69.4732 57.8134 69.4732 59.1858 70.317 60.0297C70.7383 60.451 71.2929 60.6623 71.8468 60.6623Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M76.4925 48.5095C75.65 47.667 74.2764 47.667 73.4331 48.5095C72.5892 49.3534 72.5892 50.7257 73.4331 51.5697C73.8543 51.9909 74.4089 52.2022 74.9628 52.2022C75.5167 52.2022 76.0712 51.9909 76.4925 51.5697C77.3356 50.7258 77.3357 49.3535 76.4925 48.5095Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M74.0089 39.571C73.1651 40.4142 73.1651 41.7872 74.0089 42.6305C74.4302 43.0524 74.9848 43.263 75.5386 43.263C76.0932 43.263 76.6471 43.0524 77.069 42.6305C77.9129 41.7866 77.9129 40.4143 77.069 39.571C76.2505 38.7532 74.8256 38.7532 74.0089 39.571Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M72.2474 30.8148C71.4049 31.6586 71.4049 33.031 72.2474 33.8742C72.67 34.2961 73.2232 34.5068 73.7771 34.5068C74.3317 34.5068 74.8862 34.2961 75.3075 33.8742C76.15 33.0304 76.15 31.658 75.3075 30.8148C74.463 29.9709 73.0921 29.9716 72.2474 30.8148Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M69.6994 26.5722C70.2773 26.5722 70.8205 26.3469 71.2298 25.9384C72.0736 25.0952 72.0736 23.7222 71.2298 22.8789C70.386 22.0357 69.0136 22.0357 68.1696 22.8789C67.3258 23.7227 67.3258 25.0951 68.1696 25.9384C68.5782 26.3469 69.1215 26.5722 69.6994 26.5722Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M63.3909 20.1597C63.9688 20.1597 64.512 19.9344 64.9206 19.5258C65.3298 19.1173 65.5551 18.574 65.5551 17.9961C65.5551 17.4183 65.3299 16.875 64.9206 16.4658C64.1041 15.6486 62.6776 15.6486 61.8611 16.4658C61.4519 16.8744 61.2266 17.4176 61.2266 17.9961C61.2266 18.574 61.4519 19.1173 61.8611 19.5258C62.2697 19.9344 62.813 20.1597 63.3909 20.1597Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M56.0164 15.6579C56.5709 15.6579 57.1255 15.4473 57.5468 15.0254C58.3893 14.1816 58.3893 12.8092 57.5468 11.9659C56.7016 11.1221 55.3306 11.1228 54.4866 11.9659C53.6441 12.8098 53.6441 14.1821 54.4866 15.0254C54.9092 15.4473 55.4625 15.6579 56.0164 15.6579Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M47.6186 13.2032C48.1725 13.2032 48.727 12.9926 49.1483 12.5707C49.5575 12.1621 49.7828 11.6189 49.7828 11.0403C49.7828 10.4624 49.5575 9.9192 49.1483 9.51061C48.3317 8.69343 46.9053 8.69343 46.0888 9.51061C45.6796 9.91853 45.4543 10.4624 45.4543 11.0403C45.4543 11.6182 45.6795 12.1614 46.0888 12.5707C46.5107 12.9926 47.0654 13.2032 47.6186 13.2032Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M38.5088 13.2056C39.0627 13.2056 39.6173 12.9943 40.0392 12.5731C40.4478 12.1645 40.6731 11.6213 40.6731 11.0427C40.6731 10.4648 40.4478 9.9216 40.0392 9.51302C39.1954 8.66918 37.823 8.66918 36.9797 9.51302C36.5712 9.92093 36.3459 10.4648 36.3459 11.0427C36.3459 11.6206 36.5712 12.1638 36.9797 12.5731C37.401 12.9944 37.9549 13.2056 38.5088 13.2056Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M29.3322 6.74905C29.8861 6.74905 30.44 6.53842 30.8619 6.11651C31.2705 5.70793 31.4958 5.1647 31.4958 4.58613C31.4958 4.00825 31.2705 3.46502 30.8619 3.05644C30.0447 2.23925 28.6189 2.23925 27.8025 3.05644C27.3939 3.46435 27.1686 4.00825 27.1686 4.58613C27.1686 5.16402 27.3939 5.70725 27.8025 6.11651C28.2244 6.53844 28.7783 6.74905 29.3322 6.74905Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M21.1559 10.6594C21.7338 10.6594 22.277 10.4342 22.6863 10.0256C23.5295 9.1824 23.5295 7.80938 22.6863 6.96611C21.8424 6.12228 20.4701 6.12228 19.6268 6.96611C19.2182 7.37469 18.993 7.91792 18.993 8.49649C18.993 9.07438 19.2182 9.61761 19.6268 10.0262C20.0341 10.4341 20.5774 10.6594 21.1559 10.6594Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M13.829 16.5824C14.3829 16.5824 14.9375 16.3711 15.3594 15.9498C15.768 15.5413 15.9933 14.998 15.9933 14.4195C15.9933 13.8416 15.768 13.2983 15.3594 12.8898C14.5163 12.0466 13.1439 12.0459 12.3 12.8898C11.8914 13.2977 11.6661 13.8416 11.6661 14.4195C11.6661 14.9973 11.8914 15.5406 12.3 15.9498C12.7212 16.3718 13.2751 16.5824 13.829 16.5824Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M7.99796 23.7216C8.57584 23.7216 9.11907 23.4963 9.52766 23.0878C10.3715 22.2439 10.3715 20.8716 9.52766 20.0283C8.71114 19.2111 7.28536 19.2111 6.4682 20.0283C5.62503 20.8721 5.62503 22.2445 6.4682 23.0878C6.87611 23.4963 7.42007 23.7216 7.99796 23.7216Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M4.08691 32.112C4.6408 32.112 5.19536 31.9014 5.61729 31.4795C6.46045 30.6356 6.46045 29.2633 5.61729 28.42C4.8001 27.6028 3.37431 27.6028 2.55783 28.42C1.71399 29.2638 1.71399 30.6362 2.55783 31.4795C2.97909 31.9007 3.53235 32.112 4.08691 32.112Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M2.16446 41.0467C2.71835 41.0467 3.27291 40.836 3.69484 40.4141C4.53801 39.5703 4.53801 38.1979 3.69484 37.3547C2.87766 36.5375 1.45186 36.5375 0.635378 37.3547C-0.208456 38.1985 -0.208456 39.5709 0.635378 40.4141C1.05664 40.8361 1.61057 41.0467 2.16446 41.0467Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M3.69284 49.5172C4.53668 48.6734 4.53668 47.3017 3.69284 46.4578C2.84901 45.6152 1.47665 45.6152 0.633381 46.4578C0.224799 46.8657 -0.000488281 47.4096 -0.000488281 47.9875C-0.000488281 48.5653 0.224799 49.1086 0.633381 49.5172C1.0413 49.9264 1.58519 50.1517 2.16308 50.1517C2.7403 50.1517 3.28426 49.9265 3.69284 49.5172Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M36.9782 82.807C36.1351 83.6509 36.1351 85.0232 36.9782 85.8672C37.4002 86.2885 37.9541 86.4997 38.5079 86.4997C39.0618 86.4997 39.6164 86.2884 40.0383 85.8672C40.8815 85.0234 40.8815 83.651 40.0383 82.807C39.1938 81.9639 37.8215 81.9645 36.9782 82.807Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M46.0901 82.8068C45.2463 83.6506 45.2463 85.023 46.0901 85.8669C46.5114 86.2882 47.0659 86.4995 47.6198 86.4995C48.1737 86.4995 48.7283 86.2882 49.1495 85.8669C49.9933 85.0231 49.9933 83.6508 49.1495 82.8068C48.307 81.9643 46.9334 81.9643 46.0901 82.8068Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M55.3364 80.4955C54.4925 81.34 54.4925 82.7131 55.3364 83.5556C55.759 83.9769 56.3122 84.1882 56.8668 84.1882C57.4206 84.1882 57.9752 83.9769 58.3965 83.5556C59.2403 82.7131 59.2403 81.3401 58.3965 80.4955C57.5526 79.6517 56.1796 79.6537 55.3364 80.4955Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M63.2531 76.6497C62.4106 77.4936 62.4106 78.8659 63.2531 79.7099C63.6757 80.1312 64.2289 80.3424 64.7828 80.3424C65.3373 80.3424 65.8919 80.1311 66.3131 79.7099C67.1556 78.8661 67.1556 77.4937 66.3131 76.6497C65.4673 75.8059 64.0943 75.8079 63.2531 76.6497Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M70.6109 70.7991C69.7671 71.6416 69.7671 73.0146 70.6109 73.8592C71.0335 74.2805 71.5867 74.4918 72.1406 74.4918C72.6951 74.4918 73.2497 74.2805 73.671 73.8592C74.5148 73.0147 74.5148 71.6417 73.671 70.7991C72.8251 69.9553 71.4542 69.9573 70.6109 70.7991Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M76.4742 63.9103C75.6303 64.7529 75.6303 66.1259 76.4742 66.9705C76.8968 67.3918 77.45 67.603 78.0039 67.603C78.5584 67.603 79.113 67.3917 79.5343 66.9705C80.3781 66.126 80.3781 64.7529 79.5343 63.9103C78.6884 63.0665 77.3175 63.0685 76.4742 63.9103Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M80.3867 55.4872C79.9775 55.8951 79.7522 56.439 79.7522 57.0169C79.7522 57.5948 79.9775 58.138 80.3867 58.5466C80.7947 58.9559 81.3385 59.1812 81.9164 59.1812C82.4943 59.1812 83.0375 58.9559 83.4461 58.5466C83.8554 58.1387 84.0807 57.5948 84.0807 57.0169C84.0807 56.439 83.8554 55.8958 83.4461 55.4872C82.6289 54.6694 81.2027 54.6694 80.3867 55.4872Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M85.3676 46.5852C84.5231 45.7414 83.1521 45.7401 82.3075 46.5852C81.4637 47.4291 81.4637 48.8007 82.3075 49.6447C82.7301 50.0673 83.2833 50.2772 83.8372 50.2772C84.3918 50.2772 84.9457 50.0673 85.3676 49.6447C86.2114 48.8009 86.2115 47.4292 85.3676 46.5852Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M82.3065 37.3529C81.464 38.1968 81.464 39.5691 82.3065 40.4124C82.7291 40.8337 83.2823 41.0443 83.8362 41.0443C84.3908 41.0443 84.9447 40.8336 85.3666 40.4124C86.2091 39.5686 86.2091 38.1962 85.3666 37.3529C84.5221 36.5091 83.1511 36.5098 82.3065 37.3529Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M80.3868 31.4771C80.8081 31.8991 81.3626 32.1097 81.9165 32.1097C82.4704 32.1097 83.025 31.8991 83.4462 31.4771C83.8554 31.0686 84.0807 30.5253 84.0807 29.9468C84.0807 29.3689 83.8555 28.8257 83.4462 28.4171C82.6297 27.5999 81.2032 27.5999 80.3867 28.4171C79.9775 28.8257 79.7522 29.3689 79.7522 29.9468C79.7522 30.5247 79.9775 31.0685 80.3868 31.4771Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M77.6191 23.7195C78.1737 23.7195 78.7276 23.5089 79.1495 23.087C79.992 22.2432 79.992 20.8708 79.1495 20.0275C78.3043 19.183 76.9312 19.185 76.0893 20.0275C75.2468 20.8714 75.2468 22.2437 76.0893 23.087C76.5119 23.5089 77.0659 23.7195 77.6191 23.7195Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M72.1407 16.8117C72.6952 16.8117 73.2491 16.6011 73.671 16.1792C74.5149 15.336 74.5149 13.963 73.671 13.1197C72.8259 12.2766 71.4548 12.2772 70.6109 13.1197C69.7671 13.9636 69.7671 15.3359 70.6109 16.1792C71.0335 16.6011 71.5875 16.8117 72.1407 16.8117Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M64.7808 10.6574C65.3354 10.6574 65.8893 10.4467 66.3112 10.0248C67.1551 9.18166 67.1551 7.80864 66.3112 6.96536C65.4661 6.12219 64.095 6.12286 63.2511 6.96536C62.4072 7.8092 62.4072 9.18155 63.2511 10.0248C63.6737 10.4468 64.2276 10.6574 64.7808 10.6574Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M56.4509 6.74905C57.0048 6.74905 57.5594 6.53842 57.9806 6.11651C58.3899 5.70793 58.6152 5.1647 58.6152 4.58613C58.6152 4.00825 58.3899 3.46502 57.9806 3.05644C57.1641 2.23925 55.7377 2.23925 54.9212 3.05644C54.5119 3.46435 54.2866 4.00825 54.2866 4.58613C54.2866 5.16402 54.5119 5.70725 54.9212 6.11651C55.3424 6.53844 55.8971 6.74905 56.4509 6.74905Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M47.6186 4.82325C48.1725 4.82325 48.727 4.61262 49.1483 4.19071C49.5575 3.78212 49.7828 3.2389 49.7828 2.66033C49.7828 2.08244 49.5575 1.53921 49.1483 1.13063C48.3317 0.313451 46.9053 0.313451 46.0888 1.13063C45.6796 1.53855 45.4543 2.08244 45.4543 2.66033C45.4543 3.23821 45.6795 3.78144 46.0888 4.19071C46.5107 4.61263 47.0654 4.82325 47.6186 4.82325Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M38.5088 4.82541C39.0627 4.82541 39.6173 4.61411 40.0392 4.19287C40.4478 3.78429 40.6731 3.24106 40.6731 2.66249C40.6731 2.08461 40.4478 1.54138 40.0392 1.13279C39.1954 0.289625 37.823 0.28896 36.9797 1.13279C36.5712 1.54071 36.3459 2.08461 36.3459 2.66249C36.3459 3.24038 36.5712 3.7836 36.9797 4.19287C37.401 4.61413 37.9549 4.82541 38.5088 4.82541Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M27.8025 80.8845C27.3939 81.2924 27.1686 81.8363 27.1686 82.4142C27.1686 82.9921 27.3939 83.5353 27.8025 83.9439C28.2104 84.3531 28.7543 84.5784 29.3321 84.5784C29.91 84.5784 30.4533 84.3531 30.8618 83.9439C31.2704 83.536 31.4957 82.9921 31.4957 82.4142C31.4957 81.8363 31.2704 81.2931 30.8618 80.8845C30.0453 80.066 28.6189 80.066 27.8025 80.8845Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M19.6253 76.6495C18.7822 77.4933 18.7822 78.8657 19.6253 79.7096C20.0473 80.1309 20.6005 80.3422 21.155 80.3422C21.7089 80.3422 22.2635 80.1309 22.6854 79.7096C23.5279 78.8658 23.5279 77.4934 22.6854 76.6495C21.8409 75.807 20.4686 75.807 19.6253 76.6495Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M12.3015 71.1499C11.4583 71.9938 11.4583 73.3661 12.3015 74.2101C12.7234 74.6313 13.2773 74.8426 13.8312 74.8426C14.385 74.8426 14.9396 74.6313 15.3615 74.2101C16.2047 73.3662 16.2047 71.9939 15.3615 71.1499C14.5164 70.3061 13.144 70.3074 12.3015 71.1499Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M6.46521 63.9105C5.62204 64.753 5.62204 66.126 6.46521 66.9707C6.88713 67.3919 7.44103 67.6032 7.99559 67.6032C8.54948 67.6032 9.10337 67.3919 9.52528 66.9707C9.93387 66.5614 10.1592 66.0182 10.1592 65.4403C10.1592 64.8624 9.93387 64.3192 9.52528 63.9106C8.68145 63.0674 7.30916 63.0674 6.46521 63.9105Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M5.61529 55.4877C4.79811 54.6692 3.37231 54.6692 2.55583 55.4877C2.14725 55.8956 1.92196 56.4395 1.92196 57.0174C1.92196 57.5953 2.14725 58.1385 2.55583 58.5471C2.96375 58.9564 3.50764 59.1817 4.08553 59.1817C4.66341 59.1817 5.20664 58.9564 5.61591 58.5471C6.45907 57.7033 6.45913 56.3317 5.61529 55.4877Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M31.6361 43.8641L39.4543 51.6824L55.0909 36.0458"
      stroke="black"
      strokeWidth="1.95457"
    />
  </svg>
)

export default TransactionSignedIcon
