import { SVGProps } from 'react'

const DiversityFactorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5382_334978)">
      <path
        d="M8.00044 3.82676C8.92226 3.82676 9.67642 4.58092 9.67642 5.50274C9.67642 5.98871 9.47534 6.42455 9.14005 6.72617C9.10649 6.75972 9.07306 6.79316 9.02267 6.82671C8.72105 7.12833 8.5367 7.54732 8.5367 7.99987C8.5367 8.45241 8.72106 8.8714 9.02267 9.17302C9.05622 9.20657 9.10649 9.24001 9.14005 9.27356C9.47521 9.57518 9.67642 10.011 9.67642 10.497C9.67642 11.4188 8.92226 12.173 8.00044 12.173C7.07862 12.173 6.32446 11.4188 6.32446 10.497C6.32446 10.011 6.52554 9.57518 6.86084 9.27356C6.89439 9.24001 6.92782 9.20657 6.96138 9.18974C7.26299 8.88812 7.4642 8.46913 7.4642 7.99973C7.4642 7.54719 7.27984 7.1282 6.97823 6.82658C6.94467 6.79303 6.8944 6.75959 6.86085 6.72604C6.54238 6.42442 6.32448 5.98858 6.32448 5.5026C6.32448 4.58103 7.07862 3.82676 8.00044 3.82676Z"
        fill="currentColor"
      />
      <path
        d="M3.67598 6.32407C4.60166 6.32407 5.35196 7.07437 5.35196 8.00005C5.35196 8.92573 4.60166 9.67603 3.67598 9.67603C2.75042 9.67603 2 8.92573 2 8.00005C2 7.07437 2.75042 6.32407 3.67598 6.32407Z"
        fill="currentColor"
      />
      <path
        d="M12.3242 8.82114C13.2497 8.82114 14.0001 9.57144 14.0001 10.4971C14.0001 11.4227 13.2497 12.1731 12.3242 12.1731C11.3985 12.1731 10.6482 11.4227 10.6482 10.4971C10.6482 9.57144 11.3985 8.82114 12.3242 8.82114Z"
        fill="currentColor"
      />
      <path
        d="M12.3242 3.82663C13.2497 3.82663 14.0001 4.57705 14.0001 5.50261C14.0001 6.42829 13.2497 7.17859 12.3242 7.17859C11.3985 7.17859 10.6482 6.42829 10.6482 5.50261C10.6482 4.57705 11.3985 3.82663 12.3242 3.82663Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5382_334978">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.000854492)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default DiversityFactorIcon
