import { SVGProps } from 'react'

const StackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6885 5.83333V14.1667C15.6885 14.2086 15.6636 14.3379 15.4112 14.5368C15.1651 14.7306 14.7744 14.9297 14.2414 15.1073C13.1815 15.4605 11.6817 15.6885 10 15.6885C8.31828 15.6885 6.8185 15.4605 5.75856 15.1073C5.22565 14.9297 4.83485 14.7306 4.58881 14.5368C4.33645 14.3379 4.31146 14.2086 4.31146 14.1667V5.83333C4.31146 5.79138 4.33645 5.66207 4.58881 5.46323C4.83485 5.26937 5.22565 5.07027 5.75856 4.89269C6.8185 4.53948 8.31828 4.31146 10 4.31146C11.6817 4.31146 13.1815 4.53948 14.2414 4.89269C14.7744 5.07027 15.1651 5.26937 15.4112 5.46323C15.6636 5.66207 15.6885 5.79138 15.6885 5.83333Z"
      fill="#999999"
      stroke="url(#paint0_linear_6386_29542)"
      strokeWidth="1.12292"
      strokeMiterlimit="5"
    />
    <path
      d="M15.6885 5.83333V10.5208C15.6885 10.5628 15.6636 10.6921 15.4112 10.8909C15.1651 11.0848 14.7744 11.2839 14.2414 11.4615C13.1815 11.8147 11.6817 12.0427 10 12.0427C8.31828 12.0427 6.8185 11.8147 5.75856 11.4615C5.22565 11.2839 4.83485 11.0848 4.58881 10.8909C4.33645 10.6921 4.31146 10.5628 4.31146 10.5208V5.83333C4.31146 5.79138 4.33645 5.66207 4.58881 5.46323C4.83485 5.26937 5.22565 5.07027 5.75856 4.89269C6.8185 4.53948 8.31828 4.31146 10 4.31146C11.6817 4.31146 13.1815 4.53948 14.2414 4.89269C14.7744 5.07027 15.1651 5.26937 15.4112 5.46323C15.6636 5.66207 15.6885 5.79138 15.6885 5.83333Z"
      fill="#B3B3B3"
      stroke="url(#paint1_linear_6386_29542)"
      strokeWidth="1.12292"
      strokeMiterlimit="5"
    />
    <rect
      x="3.75"
      y="4.79199"
      width="12.5"
      height="3.125"
      rx="1.5625"
      fill="black"
    />
    <mask id="path-4-inside-1_6386_29542" fill="white">
      <path d="M16.25 5.83301C16.25 6.98405 13.4505 7.91634 10 7.91634C6.54948 7.91634 3.75 6.98405 3.75 5.83301" />
    </mask>
    <path
      d="M15.9948 5.83301C15.9948 6.00904 15.888 6.21732 15.6007 6.44366C15.3163 6.66774 14.889 6.88067 14.3383 7.0642C13.2395 7.43036 11.7055 7.66113 10 7.66113V8.17155C11.7451 8.17155 13.3361 7.93618 14.4996 7.54843C15.0801 7.35501 15.5683 7.11904 15.9166 6.84459C16.2621 6.5724 16.5052 6.2325 16.5052 5.83301H15.9948ZM10 7.66113C8.29453 7.66113 6.76054 7.43036 5.66174 7.0642C5.111 6.88067 4.68368 6.66774 4.39928 6.44366C4.112 6.21732 4.00521 6.00904 4.00521 5.83301H3.49479C3.49479 6.2325 3.73793 6.5724 4.08339 6.84459C4.43172 7.11904 4.91992 7.35501 5.50037 7.54843C6.66394 7.93618 8.25495 8.17155 10 8.17155V7.66113Z"
      fill="black"
      mask="url(#path-4-inside-1_6386_29542)"
    />
    <mask id="path-6-inside-2_6386_29542" fill="white">
      <path d="M16.25 8.125C16.25 9.27604 13.4505 10.2083 10 10.2083C6.54948 10.2083 3.75 9.27604 3.75 8.125" />
    </mask>
    <path
      d="M15.4333 8.125C15.4333 8.05523 15.4765 8.11872 15.2532 8.29464C15.0392 8.46331 14.6788 8.65089 14.1608 8.82353C13.1332 9.16595 11.6619 9.39167 10 9.39167V11.025C11.7886 11.025 13.4423 10.7846 14.6771 10.3731C15.2903 10.1688 15.8454 9.90746 16.2641 9.57759C16.6735 9.25498 17.0667 8.77029 17.0667 8.125H15.4333ZM10 9.39167C8.33808 9.39167 6.8668 9.16595 5.83924 8.82353C5.32119 8.65089 4.96083 8.46331 4.74676 8.29464C4.52348 8.11872 4.56667 8.05523 4.56667 8.125H2.93333C2.93333 8.77029 3.32645 9.25498 3.73591 9.57759C4.15457 9.90746 4.70973 10.1688 5.32287 10.3731C6.55768 10.7846 8.2114 11.025 10 11.025V9.39167Z"
      fill="white"
      mask="url(#path-6-inside-2_6386_29542)"
    />
    <rect x="3.75" y="7.08301" width="12.5" height="1.04167" fill="white" />
    <rect x="3.75" y="7.08301" width="12.5" height="0.416667" fill="black" />
    <mask id="path-10-inside-3_6386_29542" fill="white">
      <path d="M16.25 7.5C16.25 8.65104 13.4505 9.58333 10 9.58333C6.54948 9.58333 3.75 8.65104 3.75 7.5" />
    </mask>
    <path
      d="M15.4333 7.5C15.4333 7.43023 15.4765 7.49372 15.2532 7.66964C15.0392 7.83831 14.6788 8.02589 14.1608 8.19853C13.1332 8.54095 11.6619 8.76667 10 8.76667V10.4C11.7886 10.4 13.4423 10.1596 14.6771 9.74809C15.2903 9.54377 15.8454 9.28246 16.2641 8.95259C16.6735 8.62998 17.0667 8.14529 17.0667 7.5H15.4333ZM10 8.76667C8.33808 8.76667 6.8668 8.54095 5.83924 8.19853C5.32119 8.02589 4.96083 7.83831 4.74676 7.66964C4.52348 7.49372 4.56667 7.43023 4.56667 7.5H2.93333C2.93333 8.14529 3.32645 8.62998 3.73591 8.95259C4.15457 9.28246 4.70973 9.54377 5.32287 9.74809C6.55768 10.1596 8.2114 10.4 10 10.4V8.76667Z"
      fill="black"
      mask="url(#path-10-inside-3_6386_29542)"
    />
    <mask id="path-12-inside-4_6386_29542" fill="white">
      <path d="M16.25 7.08301C16.25 8.23405 13.4505 9.16634 10 9.16634C6.54948 9.16634 3.75 8.23405 3.75 7.08301" />
    </mask>
    <path
      d="M15.4333 7.08301C15.4333 7.01324 15.4765 7.07673 15.2532 7.25265C15.0392 7.42132 14.6788 7.6089 14.1608 7.78153C13.1332 8.12396 11.6619 8.34967 10 8.34967V9.98301C11.7886 9.98301 13.4423 9.74258 14.6771 9.3311C15.2903 9.12678 15.8454 8.86547 16.2641 8.5356C16.6735 8.21298 17.0667 7.7283 17.0667 7.08301H15.4333ZM10 8.34967C8.33808 8.34967 6.8668 8.12396 5.83924 7.78153C5.32119 7.6089 4.96083 7.42132 4.74676 7.25265C4.52348 7.07673 4.56667 7.01324 4.56667 7.08301H2.93333C2.93333 7.7283 3.32645 8.21298 3.73591 8.5356C4.15457 8.86547 4.70973 9.12678 5.32287 9.3311C6.55768 9.74258 8.2114 9.98301 10 9.98301V8.34967Z"
      fill="url(#paint2_linear_6386_29542)"
      mask="url(#path-12-inside-4_6386_29542)"
    />
    <mask id="path-14-inside-5_6386_29542" fill="white">
      <path d="M16.25 6.66699C16.25 7.81803 13.4505 8.75033 10 8.75033C6.54948 8.75033 3.75 7.81803 3.75 6.66699" />
    </mask>
    <path
      d="M15.4333 6.66699C15.4333 6.59722 15.4765 6.66071 15.2532 6.83663C15.0392 7.0053 14.6788 7.19289 14.1608 7.36552C13.1332 7.70794 11.6619 7.93366 10 7.93366V9.56699C11.7886 9.56699 13.4423 9.32657 14.6771 8.91508C15.2903 8.71076 15.8454 8.44945 16.2641 8.11958C16.6735 7.79697 17.0667 7.31228 17.0667 6.66699H15.4333ZM10 7.93366C8.33808 7.93366 6.8668 7.70794 5.83924 7.36552C5.32119 7.19289 4.96083 7.0053 4.74676 6.83663C4.52348 6.66071 4.56667 6.59722 4.56667 6.66699H2.93333C2.93333 7.31228 3.32645 7.79697 3.73591 8.11958C4.15457 8.44945 4.70973 8.71076 5.32287 8.91508C6.55768 9.32657 8.2114 9.56699 10 9.56699V7.93366Z"
      fill="black"
      mask="url(#path-14-inside-5_6386_29542)"
    />
    <mask id="path-16-inside-6_6386_29542" fill="white">
      <path d="M16.25 6.25C16.25 7.40104 13.4505 8.33333 10 8.33333C6.54948 8.33333 3.75 7.40104 3.75 6.25" />
    </mask>
    <path
      d="M15.4333 6.25C15.4333 6.18023 15.4765 6.24372 15.2532 6.41964C15.0392 6.58831 14.6788 6.77589 14.1608 6.94853C13.1332 7.29095 11.6619 7.51667 10 7.51667V9.15C11.7886 9.15 13.4423 8.90957 14.6771 8.49809C15.2903 8.29377 15.8454 8.03246 16.2641 7.70259C16.6735 7.37998 17.0667 6.89529 17.0667 6.25H15.4333ZM10 7.51667C8.33808 7.51667 6.8668 7.29095 5.83924 6.94853C5.32119 6.77589 4.96083 6.58831 4.74676 6.41964C4.52348 6.24372 4.56667 6.18023 4.56667 6.25H2.93333C2.93333 6.89529 3.32645 7.37998 3.73591 7.70259C4.15457 8.03246 4.70973 8.29377 5.32287 8.49809C6.55768 8.90957 8.2114 9.15 10 9.15V7.51667Z"
      fill="url(#paint3_linear_6386_29542)"
      mask="url(#path-16-inside-6_6386_29542)"
    />
    <mask id="path-18-inside-7_6386_29542" fill="white">
      <path d="M16.25 5.83301C16.25 6.98405 13.4505 7.91634 10 7.91634C6.54948 7.91634 3.75 6.98405 3.75 5.83301" />
    </mask>
    <path
      d="M15.4333 5.83301C15.4333 5.76324 15.4765 5.82673 15.2532 6.00265C15.0392 6.17132 14.6788 6.3589 14.1608 6.53153C13.1332 6.87396 11.6619 7.09967 10 7.09967V8.73301C11.7886 8.73301 13.4423 8.49258 14.6771 8.0811C15.2903 7.87678 15.8454 7.61547 16.2641 7.2856C16.6735 6.96298 17.0667 6.4783 17.0667 5.83301H15.4333ZM10 7.09967C8.33808 7.09967 6.8668 6.87396 5.83924 6.53153C5.32119 6.3589 4.96083 6.17132 4.74676 6.00265C4.52348 5.82673 4.56667 5.76324 4.56667 5.83301H2.93333C2.93333 6.4783 3.32645 6.96298 3.73591 7.2856C4.15457 7.61547 4.70973 7.87678 5.32287 8.0811C6.55768 8.49258 8.2114 8.73301 10 8.73301V7.09967Z"
      fill="black"
      mask="url(#path-18-inside-7_6386_29542)"
    />
    <mask id="path-20-inside-8_6386_29542" fill="white">
      <path d="M16.25 5.83301C16.25 4.68197 13.4505 3.74967 10 3.74967C6.54948 3.74967 3.75 4.68197 3.75 5.83301" />
    </mask>
    <path
      d="M15.4333 5.83301C15.4333 5.90278 15.4765 5.83929 15.2532 5.66337C15.0392 5.4947 14.6788 5.30711 14.1608 5.13448C13.1332 4.79206 11.6619 4.56634 10 4.56634V2.93301C11.7886 2.93301 13.4423 3.17343 14.6771 3.58492C15.2903 3.78924 15.8454 4.05055 16.2641 4.38042C16.6735 4.70303 17.0667 5.18772 17.0667 5.83301H15.4333ZM10 4.56634C8.33808 4.56634 6.8668 4.79206 5.83924 5.13448C5.32119 5.30711 4.96083 5.4947 4.74676 5.66337C4.52348 5.83929 4.56667 5.90278 4.56667 5.83301H2.93333C2.93333 5.18772 3.32645 4.70303 3.73591 4.38042C4.15457 4.05055 4.70973 3.78924 5.32287 3.58492C6.55768 3.17343 8.2114 2.93301 10 2.93301V4.56634Z"
      fill="black"
      mask="url(#path-20-inside-8_6386_29542)"
    />
    <mask id="path-22-inside-9_6386_29542" fill="white">
      <path d="M16.25 6.66699C16.25 5.51595 13.4505 4.58366 10 4.58366C6.54948 4.58366 3.75 5.51595 3.75 6.66699" />
    </mask>
    <path
      d="M15.4333 6.66699C15.4333 6.73676 15.4765 6.67327 15.2532 6.49735C15.0392 6.32868 14.6788 6.1411 14.1608 5.96847C13.1332 5.62604 11.6619 5.40033 10 5.40033V3.76699C11.7886 3.76699 13.4423 4.00742 14.6771 4.4189C15.2903 4.62322 15.8454 4.88453 16.2641 5.2144C16.6735 5.53702 17.0667 6.0217 17.0667 6.66699H15.4333ZM10 5.40033C8.33808 5.40033 6.8668 5.62604 5.83924 5.96847C5.32119 6.1411 4.96083 6.32868 4.74676 6.49735C4.52348 6.67327 4.56667 6.73676 4.56667 6.66699H2.93333C2.93333 6.0217 3.32645 5.53702 3.73591 5.2144C4.15457 4.88453 4.70973 4.62322 5.32287 4.4189C6.55768 4.00742 8.2114 3.76699 10 3.76699V5.40033Z"
      fill="black"
      mask="url(#path-22-inside-9_6386_29542)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6386_29542"
        x1="10"
        y1="3.75"
        x2="10"
        y2="16.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.291668" />
        <stop offset="0.321155" stopColor="#999999" />
        <stop offset="1" stopColor="#999999" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6386_29542"
        x1="10"
        y1="3.75"
        x2="10"
        y2="16.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.291668" />
        <stop offset="0.321155" stopColor="#B3B3B3" />
        <stop offset="1" stopColor="#B3B3B3" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6386_29542"
        x1="10"
        y1="7.08301"
        x2="10"
        y2="9.16634"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_6386_29542"
        x1="10"
        y1="6.25"
        x2="10"
        y2="8.33333"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
)
export default StackIcon
