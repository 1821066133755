import { SVGProps } from 'react'

const GovernanceActionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="48px"
    height="48px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4737 13.7371C18.4737 16.3424 16.3423 18.4738 13.7369 18.4738L13.7368 18.4738C11.1317 18.4738 9 16.3421 9 13.7368C9 11.0527 11.1317 9 13.7368 9L34.2631 9.00028C36.8684 9.00028 38.9999 11.1317 38.9999 13.7371C38.9999 16.3424 36.8684 18.4738 34.2631 18.4738C31.6578 18.4738 29.5263 16.3424 29.5263 13.7371H18.4737ZM36.6318 13.7371C36.6318 12.3948 35.6053 11.3686 34.2632 11.3686C32.9209 11.3686 31.8947 12.3948 31.8947 13.7371C31.8947 15.0794 32.9212 16.1057 34.2632 16.1057C35.6056 16.1057 36.6318 15.0792 36.6318 13.7371ZM16.1055 13.7371C16.1055 12.3948 15.079 11.3686 13.7369 11.3686C12.3948 11.3686 11.3686 12.3948 11.3684 13.7371C11.3684 15.0794 12.3949 16.1057 13.7369 16.1057C15.0792 16.1057 16.1055 15.0792 16.1055 13.7371ZM35.8423 19.8158V39.0001H33.4746V24.7895C33.4746 23.4472 32.448 22.421 31.106 22.421C29.8427 22.421 28.7374 23.4475 28.7374 24.7895L28.7377 39.0001H26.3692V20.8422C26.3692 19.4998 25.3426 18.4736 24.0006 18.4736C22.7373 18.4736 21.632 19.5001 21.632 20.8422L21.6326 39.0001H19.264V24.7895C19.264 23.4472 18.2375 22.421 16.8955 22.421C15.6322 22.421 14.5269 23.4475 14.5269 24.7895V39.0001H12.1583L12.1586 19.8158C12.6321 19.9736 13.1848 20.0526 13.7374 20.0526C16.6583 20.0526 19.1058 17.9999 19.8162 15.3159H28.1846C28.816 17.9999 31.3423 20.0526 34.2635 20.0526C34.816 20.0526 35.3687 19.9736 35.8423 19.8158Z"
      fill="currentColor"
    />
  </svg>
)

export default GovernanceActionIcon
